import { Base } from '@gate31/core/src/libs/BaseComponent';
import Gate31Api from '@gate31/core/src/api/gate31-api';
import { Maxma } from '@gate31/core/src/api/maxma';
import { OrderStatus } from '@gate31/types/order-statuses';
import { ClientResponseInt } from '@gate31/types/client';
import { faro } from '@gate31/faro/src';
import { AccountOrdersDesk } from '../AccountOrdersDesk/AccountOrdersDesk';
import AccountActiveOrdersDesk from '../AccountActiveOrdersDesk/AccountActiveOrdersDesk';
import { AccountProfileDesk } from '../AccountProfileDesk/AccountProfileDesk';
import { EditSubscriptionsDesk } from '../EditSubscriptionsDesk/EditSubscriptionsDesk';
import { AccountMilesDesk } from '../AccountMilesDesk/AccountMilesDesk';

export interface OrderData {
    number: string;
    date: string;
    status: string;
    url: string;
    payStatus: string;
    price: number;
    color: string;
    isActive: boolean;
}

const GROUP_OF_ACTIVE_STATUSES = [
    'new', 'accepted', 'approved', 'dispatched', 'returning'
];

export class AccountClientDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('AccountClientDesk');

    clientData: ClientResponseInt;
    customContainer: HTMLDivElement;
    baseContainer: HTMLDivElement;

    loader: HTMLDivElement;

    constructor(node: HTMLElement, clientData: ClientResponseInt) {
        super(node);

        // eslint-disable-next-line
        new AccountProfileDesk(AccountProfileDesk.querySelector<HTMLDivElement>(AccountProfileDesk.cn.toSelector()), clientData);

        this.clientData = clientData;
        this.customContainer = AccountClientDesk.querySelector(AccountClientDesk.cn.setElem('custom-form').toSelector()) as HTMLDivElement;
        this.baseContainer = AccountClientDesk.querySelector(AccountClientDesk.cn.setElem('base-form').toSelector()) as HTMLDivElement;

        this.loader = AccountClientDesk.querySelector<HTMLDivElement>(AccountClientDesk.cn.setElem('loader').toSelector());

        this.callbackConnection();
    }

    callbackConnection() {
        this.initPage();

        Maxma.getBalance(this.clientData.client.phone ?? '')
            .then(res => res.json())
            .then(maxmaData => {
                // eslint-disable-next-line
                new EditSubscriptionsDesk(EditSubscriptionsDesk.querySelector(EditSubscriptionsDesk.cn.toSelector()), {
                    clientData: this.clientData,
                    maxmaData
                });
                // eslint-disable-next-line
                new AccountMilesDesk(AccountMilesDesk.querySelector(AccountMilesDesk.cn.toSelector()), {
                    clientData: this.clientData,
                    maxmaData
                });
            })
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MaxmaApi method getBalance is ERROR - ${error}`
                    }
                });
            });
    }

    initOrderInfo() {
        this.getOrdersData().then(orders => {
            // eslint-disable-next-line
            new AccountOrdersDesk(AccountOrdersDesk.querySelector<HTMLDivElement>(AccountOrdersDesk.cn.toSelector()), orders);
            // eslint-disable-next-line
            new AccountActiveOrdersDesk(AccountActiveOrdersDesk.querySelector<HTMLDivElement>(AccountActiveOrdersDesk.cn.toSelector()), orders);
        });
    }

    dateFormat(string: string): string {
        const dateArray = string.split('.');
        const day = dateArray[0];
        const month = dateArray[1];
        const year = dateArray[2];

        return `${day}.${month}.${year.split('').filter((el, ind) => ind >= 2).join('')}`;
    }

    getOneOrderDataInHTML(lineNode: HTMLTableCellElement, statusList: Array<OrderStatus>): OrderData {
        const dateNode = lineNode.querySelector('[data-title="Дата оформления"]');
        const numberNode = lineNode.querySelector('[data-title="Номер заказа"] a');
        const statusNode = lineNode.querySelector('[data-title="Статус"]');
        const payStatusNode = lineNode.querySelector('[data-title="Оплата"]');
        const priceNode = lineNode.querySelector('[data-title="Сумма заказа"]');

        const date = dateNode ? this.dateFormat(dateNode.innerHTML.trim()) : '';
        const number = numberNode ? numberNode.innerHTML.trim() : '';
        const status = statusNode ? statusNode.innerHTML.trim() : '';
        const payStatus = payStatusNode ? payStatusNode.innerHTML.trim() : '';
        const price = priceNode ? parseInt(priceNode.innerHTML.trim().replaceAll(' ', ''), 10) : 0;
        const url = numberNode ? numberNode.getAttribute('href') as string : '';

        const currentStatusGroup = statusList.find(statusOj => statusOj.title === status);
        const currentStatusGroupName = currentStatusGroup?.system_status;
        const isActive = Boolean(GROUP_OF_ACTIVE_STATUSES.find(element => element === currentStatusGroupName));

        let color = 'default';

        if (status === 'Доставлен') {
            color = 'green';
        } else if (status === 'Возврат') {
            color = 'red';
        }

        return {
            number,
            date: date?.split(' ')[0] as string,
            status,
            url,
            payStatus,
            price,
            color,
            isActive
        };
    }

    async getOrdersData(): Promise<Array<OrderData>> {
        const tableNode = this.baseContainer.querySelector('.co-table--to_card');
        const linesNodes = tableNode ? tableNode.querySelectorAll<HTMLTableCellElement>('.co-table-row--striped') : undefined;
        const statusList = await Gate31Api.getCustomStatus().then(res => res.response);
        const result: OrderData[] = [];

        linesNodes ? linesNodes.forEach(lineNode => {
            const orderData = this.getOneOrderDataInHTML(lineNode, statusList);

            result.push(orderData);
        }) : undefined;

        return result;
    }

    removeLoader() {
        if (! this.loader) {
            return;
        }

        this.loader.remove();
    }

    openCustomForm() {
        const node = AccountClientDesk.querySelector(AccountClientDesk.cn.setElem('custom-form').toSelector());

        node.classList.remove(AccountClientDesk.cn.setElem('custom-form').setMode({ hidden: true }).toString());
    }

    openBaseForm() {
        const node = AccountClientDesk.querySelector(AccountClientDesk.cn.setElem('base-form').toSelector());

        node.classList.remove(AccountClientDesk.cn.setElem('base-form').setMode({ hidden: true }).toString());
    }

    redirectToIndexPage() {
        window.location.href = '/client_account/orders';
    }

    initPage() {
        const pathName = window.location.pathname;

        this.removeLoader();

        switch (pathName) {
            case '/client_account/orders':
                this.initOrderInfo();
                this.openCustomForm();
                break;
            case '/client_account/addresses/new':
                this.redirectToIndexPage();
                break;
            case '/client_account/discounts':
                this.redirectToIndexPage();
                break;
            case '/client_account/contacts':
                this.redirectToIndexPage();
                break;
            default:
                this.openBaseForm();
                break;
        }
    }
}
