import $ from 'jquery';
import { faro } from '@gate31/faro/src';
import { Order, OrderLine } from '@gate31/types/order';
import { stringify } from '@gate31/core/src/libs/query-string';
import { subscriptionsItem } from '@gate31/touch/components/edit-subscriptions/edit-subscriptions';

export const HOST_MINDBOX = 'https://api.mindbox.ru/v3/operations';
export const AUTH_HEADER = 'Mindbox secretKey="sLBZjWXWQrrCpXo80p74"';

interface params {
    [key: string]: string;
}
interface headers {
    'Content-Type'?: string;
    Authorization?: string;
}
// eslint-disable-next-line
type body = orderingBody | addViewedBody | productViewBody | categoryViewBody | serCartBody | SetFavareteBody | SingupBody | EditBody | SubscribeBody | getUserSubscribeListBody | productRecomendBody;

interface Ids {
    siteid: number;
}
export interface Subscriptions {
    brand: 'gate31';
    pointOfContact: 'Email';
    topic?: string;
    isSubscribed?: string;
}
interface CustomerEdit {
    email: string;
    subscriptions: Subscriptions[];
    fullName?: string;
    mobilePhone?: string;
    ids?: Ids;
}
interface CustomerSubscribe {
    email: string;
    subscriptions: Subscriptions[];
}
interface SubscriptionsSingup {
    isSubscribed: string;
}

interface orderItem {
    minPricePerItem: number;
    costPricePerItem: number;
    basePricePerItem: number;
    quantity: number;
    quantityType: string;
    discountedPricePerLine: number;
    lineId: number;
    lineNumber: number;
    product: {
        ids: {
            gate31: number;
        };
    };
}
interface dataForm {
    email: string;
    fullName: string;
    mobilePhone: string;
    isSubscribed: string;
}

interface SubscribeBody {
    customer: CustomerSubscribe;
}
interface EditBody {
    customer: CustomerEdit;
}
interface SingupBody {
    customer: {
        email: string;
        fullName: string;
        mobilePhone: string;
        subscriptions: SubscriptionsSingup[];
    };
}
interface SetFavareteBody {
    productList: {
        product: {
            ids: {
                gate31: number;
            };
        };
        count: number;
        pricePerItem?: string | null;
    }[];
}
export interface serCartBody {
    productList: {
        product: {
            ids: {
                gate31: number;
            };
        };
        count: number;
        pricePerItem: number;
    }[];
}
interface categoryViewBody {
    viewProductCategory: {
        productCategory: {
            ids: {
                gate31: number;
            };
        };
    };
}
interface productViewBody {
    viewProduct: {
        product: {
            ids: {
                gate31: number;
            };
        };
    };
}
interface addViewedBody {
    addProductToList: {
        product: {
            ids: {
                gate31: number;
            };
        };
        pricePerItem: string;
    };
}
export interface editByEmailBody {
    customer: {
        email: string;
        subscriptions: Array<subscriptionsItem>;
    };
}
interface orderingBody {
    customer: {
        ids: {
            siteid: number;
        };
        fullName: string;
        email: string;
        mobilePhone: string;
    };
    order: {
        ids: {
            onlineorderid: number;
        };
        deliveryCost: number;
        totalPrice: number;
        lines: orderItem[];
        email: string;
        mobilePhone: string;
    };
}
interface getUserSubscribeListBody {
    customer: {
        email: string;
    };
}
interface productRecomendBody {
    recommendation: {
        limit: number;
        area: {
            ids: {
                externalId: '' | string;
            };
        };
    };
}

class MindBoxApiError extends Error {
    name = 'MindBoxApiError';
}

export class MindboxApi {
    static headers = {
        'Content-Type': 'application/json',
        Authorization: AUTH_HEADER
    }
    static commonGetParams = {
        endpointId: 'gate31',
        operation: 'subscribed'
    }

    static getDeviceUUID() {
        const deviceUUID = $ && $.cookie ? $.cookie('mindboxDeviceUUID') : null;

        if (deviceUUID) {
            return deviceUUID;
        }

        return null;
    }

    static subscribe(body: SubscribeBody, params: params | null, headers: headers | null) {
        return MindboxApi.send(body, params, headers);
    }

    static editByEmail(body: editByEmailBody) {
        const params = { operation: 'Redak' };

        return MindboxApi.send(body, params, null)
            .then(res => res)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method edit is ERROR - ${error}`
                    }
                });

                return;
            });
    }

    static editProfile() {
        $.getJSON('/client_account/contacts.json', data => {
            const i = {
                customer: {
                    email: String($('#client_email').val()),
                    fullName: String($('#client_name').val()),
                    mobilePhone: String($('#client_phone').val()),
                    ids: {
                        siteid: data.client.id
                    },
                    subscriptions: [
                        {
                            brand: 'gate31',
                            pointOfContact: 'Email',
                            isSubscribed: String($('#client_subscribe').is(':checked'))
                        }
                    ]
                }
            };

            const params = { operation: 'edit' };

            return MindboxApi.send(i, params, null)
                .then(res => res)
                .catch(error => {
                    faro.api.pushError(error, {
                        context: {
                            section: `MindBoxApi method editProfile is ERROR - ${error}`
                        }
                    });

                    return;
                });
        });
    }

    static setFavorite(body: serCartBody) {
        if (! MindboxApi.getDeviceUUID()) {
            return;
        }

        const params = { operation: 'setfavorites' };

        return MindboxApi.send(body, params, null)
            .then(data => data)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method setfavorites is ERROR - ${error}`
                    }
                });

                return;
            });
    }

    static signup(dataForm: dataForm) {
        const body = {
            customer: {
                email: dataForm.email,
                fullName: dataForm.fullName,
                mobilePhone: dataForm.mobilePhone,
                subscriptions: [
                    {
                        isSubscribed: dataForm.isSubscribed
                    }
                ]
            }
        };
        const params = { operation: 'signup' };

        return MindboxApi.send(body, params, null)
            .then(data => data)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method signup is ERROR - ${error}`
                    }
                });

                return;
            });
    }

    static setCart(body: serCartBody) {
        if (! MindboxApi.getDeviceUUID()) {
            return Promise.resolve();
        }

        const params = { operation: 'setcart' };

        MindboxApi.send(body, params, null)
            .then(data => data)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method setCart is ERROR - ${error}`
                    }
                });

                return;
            });
    }

    static categoryView(collectionId: number) {
        if (! MindboxApi.getDeviceUUID()) {
            return Promise.resolve();
        }

        const params = { operation: 'categoryview' };
        const body = {
            viewProductCategory: {
                productCategory: {
                    ids: {
                        gate31: collectionId
                    }
                }
            }
        };

        MindboxApi.send(body, params, null)
            .then(data => data)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: 'CategoryView'
                    }
                });

                return;
            });
    }

    static productView(productId: number) {
        if (! MindboxApi.getDeviceUUID()) {
            return Promise.resolve();
        }

        const params = { operation: 'productview' };
        const body = {
            viewProduct: {
                product: {
                    ids: {
                        gate31: productId
                    }
                }
            }
        };

        MindboxApi.send(body, params, null)
            .then(data => data)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method productView is ERROR - ${error}`
                    }
                });

                return;
            });
    }

    static addViewed(productId: number, productPrice: string) {
        if (! MindboxApi.getDeviceUUID()) {
            return Promise.resolve();
        }

        const params = { operation: 'addviewed' };
        const body = {
            addProductToList: {
                product: {
                    ids: {
                        gate31: productId
                    }
                },
                pricePerItem: productPrice
            }
        };

        MindboxApi.send(body, params, null)
            .then(data => data)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method addViewed is ERROR - ${error}`
                    }
                });

                return;
            });
    }

    static ordering(order: Order) {
        if (! MindboxApi.getDeviceUUID()) {
            return Promise.resolve();
        }

        const params = { operation: 'addviewed' };
        const lines: orderItem[] = [];

        order.order_lines.forEach((item: OrderLine, i) => {
            const orderItem = {
                minPricePerItem: item.sale_price,
                costPricePerItem: item.sale_price,
                basePricePerItem: item.sale_price,
                quantity: item.quantity,
                quantityType: 'int',
                discountedPricePerLine: item.sale_price,
                lineId: item.id,
                lineNumber: i,
                product: {
                    ids: {
                        gate31: item.product_id
                    }
                }
            };

            lines.push(orderItem);
        });

        const body = {
            customer: {
                ids: {
                    siteid: order.client.id
                },
                fullName: order.client.name,
                email: order.client.email,
                mobilePhone: order.client.phone
            },
            order: {
                ids: {
                    onlineorderid: order.id
                },
                deliveryCost: order.delivery_price,
                totalPrice: order.total_price,
                lines,
                email: order.client.email,
                mobilePhone: order.client.phone
            }
        };

        MindboxApi.send(body, params, null)
            .then(data => data)
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method ordering is ERROR - ${error}`
                    }
                });

                return;
            });
    }

    static getUserSubscribeList(email: string) {
        const params = { operation: 'SubscribeInfo' };

        const body = { customer: { email } };

        return MindboxApi.send(body, params, null, 'sync')
            .then(data => {
                return data;
            })
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: `MindBoxApi method getUserSubscribeList is ERROR - ${email} - ${error}`
                    }
                });

                return [];
            });
    }

    static send(body: body, params: params | null, headers: headers | null, syncType = 'async') {
        const url = `${HOST_MINDBOX}/${syncType}?${stringify({
            ...MindboxApi.commonGetParams,
            ...params,
            deviceUUID: MindboxApi.getDeviceUUID()
        }, null)}`;

        return fetch(url, {
            method: 'POST',
            headers: { ...headers, ...MindboxApi.headers },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(response => {
                if (! response.status || response.status !== 'Success') {
                    throw new Error(JSON.stringify(response));
                }

                return response;
            })
            .catch((error: Error) => {
                throw new MindBoxApiError(error.message);
            });
    }
}
