import { faro } from '@gate31/faro/src';
import Inputmask from 'inputmask';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { Gate31ApiV2 } from '@gate31/core/src/api/gate31-api';
import { validateEmail } from '@gate31/core/src/libs/utils';
import { ConfirmPhoneDesk } from '../ConfirmPhoneDesk/ConfirmPhoneDesk';
import { PasswordToggleDesk } from '../PasswordToggleDesk/PasswordToggleDesk';

interface FieldError {
    fieldName: string;
    isEmpty: boolean;
}

export default class CreateAccountDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('CreateAccountDesk');

    form: HTMLFormElement;
    formButton: HTMLButtonElement;
    loader: HTMLDivElement;

    im: Inputmask.Instance;
    dataAttrField: string;
    configPhone: ConfirmPhoneDesk;
    passwordToggleDesk: PasswordToggleDesk;
    cb?: () => void

    constructor(node: HTMLElement, cb?: () => {}) {
        super(node);

        this.cb = cb;
        this.im = new Inputmask('99.99.9999', { showMaskOnHover: false });
        this.im.mask('input[type="text"].type-date');
        this.passwordToggleDesk = new PasswordToggleDesk('password-toggle-desk-template');

        this.dataAttrField = 'data-input-wrap';

        this.form = CreateAccountDesk.querySelector<HTMLFormElement>(CreateAccountDesk.cn.setElem('form').toSelector());
        this.loader = CreateAccountDesk.querySelector<HTMLDivElement>(CreateAccountDesk.cn.setElem('loader').toSelector());
        this.formButton = CreateAccountDesk.querySelector<HTMLButtonElement>(CreateAccountDesk.cn.setElem('button').toSelector());

        if (! this.form) {
            return;
        }

        this.callbackConnection();
    }

    callbackConnection() {
        this.listenerField();
        this.listenerConfirmField();
        this.handleForm();

        this.configPhone = new ConfirmPhoneDesk({
            node: ConfirmPhoneDesk.querySelector(ConfirmPhoneDesk.cn.toSelector()),
            id: 'create-account-page'
        });
    }

    showLoader() {
        this.loader.classList.add('show');
    }

    hideLoader() {
        this.loader.classList.remove('show');
    }

    handleForm() {
        this.form.addEventListener('submit', e => {
            e.preventDefault();

            this.formatFormFieldsToCorrectValue();
            const formData = new FormData(this.form);
            const formValidationResult = this.fieldValidation(formData);

            if (! formValidationResult.valid) {
                formValidationResult.invalidFieldName.forEach(error => {
                    if (error.fieldName === 'phone' && error.isEmpty === false) {
                        // За вывод ошибок для телефона отвечает класс confirmPhone
                        this.configPhone.renderNotConfirmError();
                    } else {
                        this.openFieldError(error);
                    }
                });

                return;
            }

            this.showLoader();

            const formBody = this.prepareFormData(formData);

            Gate31ApiV2.createAccount(formBody)
                .then(response => {
                    this.hideLoader();

                    if (response?.status === 'Success') {
                        this.openResultMessage();

                        setTimeout(() => {
                            this.redirectToAccount();
                        }, 7000);

                        if (this.cb) {
                            this.cb();
                        }
                    }

                    if (response.error) {
                        const errorCode = response?.error?.cause?.code?.code;
                        const cause = response?.error?.cause;
                        const code = cause ? cause?.code?.code : 0;
                        const message = cause ? cause?.phone?.[0] || cause?.email?.[0] : '';

                        if (message) {
                            this.openRequestError(message);
                            return;
                        }

                        if (code) {
                            this.handleRequestError({
                                code: errorCode
                            });
                            return;
                        }
                    }

                    throw response.error;
                })
                .catch(error => {
                    try {
                        faro.api.pushError(error, {
                            context: {
                                apiMethod: 'createAccount',
                                component: 'CreateAccountDesk',
                                jsonError: JSON.stringify(error)
                            }
                        });
                    } catch (sendError) {
                        faro.api.pushError(sendError as Error, {
                            context: {
                                apiMethod: 'createAccount',
                                component: 'CreateAccountDesk'
                            }
                        });
                    }

                    this.hideLoader();

                    this.openRequestError('Что-то пошло не так. Пожалуйста повторите попытку...');
                });
        });
    }

    handleRequestError(error: { code: string }) {
        switch (error.code) {
            case 'INVALID_PHONE':
                this.openRequestError('Укажите корректный номер телефона');
                break;
            case 'INVALID_DATE':
                this.openRequestFieldError('Некорректная дата', 'dateOfBirth');
                break;
            case 'INVALID_EMAIL':
                this.openRequestError('Укажите корректный email');
                break;
            case 'INVALID_USER_NAME':
                this.openRequestError('Необходимо указать имя');
                break;
            case 'INVALID_PASSWORD':
                this.openRequestError('Длина пароля не должна быть меньше 6 символов');
                break;
            case 'ERROR_GRECAPTCHA':
                this.openRequestError('Подтвердите что вы не робот');
                break;
            default:
                this.openRequestError('Что-то пошло не так. Пожалуйста повторите попытку...');
                break;
        }
    }

    redirectToAccount() {
        window.location.href = '/client_account/login';
    }

    openRequestFieldError(message: string, field: string) {
        const node = CreateAccountDesk.querySelector(CreateAccountDesk.cn.setElem('field-wrap').setMode({ [field]: true }).toSelector());
        const nodeErrorMessage = node.querySelector<HTMLElement>(CreateAccountDesk.cn.setElem('field-error').toSelector());

        if (nodeErrorMessage) {
            nodeErrorMessage.innerHTML = message;
            nodeErrorMessage.classList.add(CreateAccountDesk.cn.setElem('field-error').setMode({ active: true }).toString());
        }
    }

    openRequestError(message: string) {
        const errorNode = CreateAccountDesk.querySelector(CreateAccountDesk.cn.setElem('result-error').toSelector());

        errorNode.innerHTML = message;
        errorNode?.classList.add(CreateAccountDesk.cn.setElem('field-error').setMode({ active: true }).toString());

        setInterval(() => {
            errorNode?.classList.remove(CreateAccountDesk.cn.setElem('field-error').setMode({ active: true }).toString());
        }, 5000);
    }

    openResultMessage() {
        const successNode = CreateAccountDesk.querySelector(CreateAccountDesk.cn.setElem('success').toSelector());
        const bodyNode = CreateAccountDesk.querySelector(CreateAccountDesk.cn.setElem('form-body').toSelector());

        const successHiddenClass = CreateAccountDesk.cn.setElem('success').setMode({ hidden: true }).toString();
        const bodyHiddenClass = CreateAccountDesk.cn.setElem('form-body').setMode({ hidden: true }).toString();

        successNode.classList.remove(successHiddenClass);
        bodyNode.classList.add(bodyHiddenClass);
    }

    listenerField() {
        this.form.addEventListener('input', e => {
            const fieldName = (e.target as HTMLInputElement).getAttribute('name') as string;

            if (fieldName) {
                this.hiddenFieldError(fieldName);
            }
        });
    }

    listenerConfirmField() {
        const confirmField = CreateAccountDesk.querySelector<HTMLInputElement>(CreateAccountDesk.cn.setElem('checkbox').setElem('checkbox-input').toSelector() + '[name="is-confirm"]');

        confirmField.addEventListener('change', () => {
            if (confirmField.checked) {
                this.unableFormButton();
            } else {
                this.disabledFormButton();
            }
        });
    }

    disabledFormButton() {
        this.formButton.setAttribute('disabled', 'true');
    }

    unableFormButton() {
        this.formButton.removeAttribute('disabled');
    }

    formatFormFieldsToCorrectValue(): void {
        // Iterate over all fields when trimming is needed
        for (const fieldName of [ 'firstName', 'lastName', 'email' ]) {
            const fieldWhenNeedTrimming = this.form.querySelector<HTMLInputElement>(`input[name="${fieldName}"]`);

            if (fieldWhenNeedTrimming) {
                fieldWhenNeedTrimming.value = fieldWhenNeedTrimming.value.trim();
            }
        }
    }

    fieldValidation(formData: FormData): { valid: false; invalidFieldName: Array<FieldError> } | { valid: true } {
        let isValid = true;
        const errorList: Array<FieldError> = [];

        if (! /[0-9][0-9]\.[0-9][0-9]\.[0-9][0-9][0-9][0-9]/.test((formData.get('dateOfBirth') as string)) && (formData.get('dateOfBirth') as string) !== '') {
            isValid = false;
            errorList.push({
                fieldName: 'dateOfBirth',
                isEmpty: false
            });
        }

        if (! formData.get('firstName')) {
            isValid = false;
            errorList.push({
                fieldName: 'firstName',
                isEmpty: true
            });
        }
        if (formData.get('email') && ! validateEmail(formData.get('email'))) {
            isValid = false;
            errorList.push({
                fieldName: 'email',
                isEmpty: false
            });
        }

        if (! formData.get('email')) {
            isValid = false;
            errorList.push({
                fieldName: 'email',
                isEmpty: true
            });
        }

        if (formData.get('password') !== formData.get('password-2')) {
            isValid = false;
            errorList.push({
                fieldName: 'password-2',
                isEmpty: false
            });
        }

        if (! this.configPhone.$phoneField.value && ! this.configPhone.confirmed) {
            errorList.push({
                fieldName: 'phone',
                isEmpty: true
            });
        }

        if (this.configPhone.$phoneField.value && ! this.configPhone.confirmed) {
            isValid = false;
            errorList.push({
                fieldName: 'phone',
                isEmpty: false
            });
        }

        if (isValid === false) {
            return {
                valid: false,
                invalidFieldName: errorList
            };
        }

        return {
            valid: true
        };
    }

    openFieldError(error: FieldError) {
        const node = CreateAccountDesk.querySelector(CreateAccountDesk.cn.setElem('field-wrap').setMode({ [error.fieldName]: true }).toSelector());
        const nodeErrorMessage = node.querySelector<HTMLElement>(CreateAccountDesk.cn.setElem('field-error').toSelector());

        if (error.isEmpty) {
            node.classList.add(CreateAccountDesk.cn.setElem('field-wrap').setMode({ empty: true }).toString());
        } else {
            if (nodeErrorMessage) {
                nodeErrorMessage.classList.add(CreateAccountDesk.cn.setElem('field-error').setMode({ active: true }).toString());
            }
        }
    }

    hiddenFieldError(fieldName: string) {
        const node = CreateAccountDesk.querySelector(CreateAccountDesk.cn.setElem('field-wrap').setMode({ [fieldName]: true }).toSelector());
        const nodeErrorMessage = node.querySelector<HTMLElement>(CreateAccountDesk.cn.setElem('field-error').toSelector());

        if (node) {
            node.classList.remove(CreateAccountDesk.cn.setElem('field-wrap').setMode({ empty: true }).toString());
        }

        if (nodeErrorMessage) {
            nodeErrorMessage.classList.remove(CreateAccountDesk.cn.setElem('field-error').setMode({ active: true }).toString());
        }
    }

    transformDateOfBirth(date: string): string {
        return date ? date.split('.').reverse().join('-') || '' : '';
    }

    prepareFormData(formData: FormData) {
        const paramsFromUrl = new URLSearchParams(window.location.search);
        const shopValue = paramsFromUrl.get('shop') ? decodeURI((paramsFromUrl.get('shop') as string)) : '';

        const opts: {
            grecaptcha?: string;
            isUseCaptcha: boolean;
        } = {
            isUseCaptcha: false
        };

        const phoneField = this.form.querySelector<HTMLInputElement>('[name="phone"]');
        const phone = phoneField ? phoneField.value : null;

        const dateOfBirth = this.transformDateOfBirth(formData.get('dateOfBirth') as string);

        const body = {
            ...opts,
            email: formData.get('email') as string,
            phone: phone as string,
            password: formData.get('password') ? formData.get('password') as string : '',
            dateOfBirth,
            name: {
                firstName: formData.get('firstName') as string,
                lastName: formData.get('lastName') ? formData.get('lastName') as string : ''
            },
            endpoint: {
                shop: shopValue
            },
            subscriptions: {
                email: true,
                phone: true
            }
        };

        return body;
    }
}
