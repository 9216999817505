import Swiper, { SwiperOptions } from 'swiper';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { faro } from '@gate31/faro/src';

class TickerDeskError extends Error {
    name = 'TickerDeskError'
}

export class TickerDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('TickerDesk');

    indexAttribute = 'data-index';
    speedAttribute = 'data-transition-speed';

    uniqueSelector: string;
    speed: string;

    constructor(node: HTMLDivElement) {
        super(node);

        // @ts-expect-error
        this.uniqueSelector = this.getUniqueSelector(node);
        // @ts-expect-error
        this.speed = this.getSpeed(node);

        this.initSlider({
            selector: this.uniqueSelector,
            speed: Number(this.speed)
        });
    }

    getUniqueSelector(node: HTMLDivElement) {
        const index = node.getAttribute(this.indexAttribute);

        if (! index) {
            const error = new TickerDeskError('missing required parameter: index');

            return faro.api.pushError(error, {
                context: {
                    section: `TickerDesk: getUniqueSelector method  - ${error}`
                }
            });
        }

        return `${TickerDesk.cn.toSelector()}[${this.indexAttribute}="${index}"]`;
    }

    getSpeed(node: HTMLDivElement) {
        const speed = node.getAttribute(this.speedAttribute) || '1000';

        if (! speed) {
            const error = new TickerDeskError('missing required parameter: speed');

            return faro.api.pushError(error, {
                context: {
                    section: `TickerDesk: getSpeed method  - ${error}`
                }
            });
        }

        return speed;
    }

    static init() {
        const tickerList = TickerDesk.querySelectorAll<HTMLDivElement>(TickerDesk.cn.toSelector());

        tickerList.forEach(ticker => {
            // eslint-disable-next-line
            new TickerDesk(ticker);
        });
    }

    initSlider({ speed, selector }: { speed: number; selector: string }) {
        const option = {
            loop: true,
            slidesPerView: 'auto',
            speed,
            autoplay: { delay: 1 }
        } as SwiperOptions;

        // eslint-disable-next-line
        new Swiper(selector, option);
    }
}
