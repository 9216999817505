import { Loyalty } from '@gate31/touch/components/cart-loyalty/cart-loyalty';
import { ClientMaxmaI } from '@gate31/touch/components/cart-loyalty/cart-loyalty';
import { ClientResponseErrorInt, ClientResponseInt } from '@gate31/types/client';
import Gate31Api from '@gate31/core/src/api/gate31-api';
import { faro } from '@gate31/faro/src';
import LiquidRender from '../../scripts/liquid-render';

const statusGroupsActiveOrder = [
    'new', 'accepted', 'approved', 'dispatched', 'returning'
];

interface TypeallClietnData {
    profil: {
        email: string;
        name: string;
        phone: string;
        dress: string;
        countryCode: string;
        strit: string;
    };
}
interface TypeOrderData {
    number: string;
    create: string;
    status: string;
    paid: string;
    price: string;
    color: string;
    isActive: boolean;
}

class ClientError extends Error {}

export default class Client {
    url: string;
    allClietnData: TypeallClietnData | {} = {};
    constructor() {
        this.allClietnData = {};
    }

    static getGroupId(): Promise<number | null> {
        return Client.get()
            .then(user => (user as ClientResponseInt).client.client_group_id)
            .catch(() => null);
    }

    // Возвращает обьект клиента
    static get(): Promise<ClientResponseInt | ClientResponseErrorInt> {
        return fetch('/client_account/contacts.json')
            .then(data => data.json())
            .catch(error => {
                faro.api.pushError(error, {
                    context: {
                        section: 'insales',
                        component: 'get-client'
                    }
                });

                throw new ClientError(error);
            });
    }

    static async getOrdersDataHTML() {
        const orderListData: TypeOrderData[] = [];
        const orderList = document.querySelectorAll('.clietn-order-content-hidden .co-table-row.co-table-row--body.co-table-row--striped');

        const statusList = await Gate31Api.getCustomStatus()
            .then(res => res.response);

        orderList.forEach(order => {
            let number = '';
            let create = '';
            let status = '';
            let link = '#';
            let paid = '';
            let price = '';
            let color = 'default';
            let isActive = true;

            const infoItemOfOrder = order.querySelectorAll('.co-table-cell--body');

            infoItemOfOrder.forEach(infoItem => {
                const dataName = infoItem.getAttribute('data-title');

                function replace(str: string) {
                    return str.split('\n').join('').replace(/\s+/g, ' ').trim();
                }

                if (infoItem === null || infoItem === undefined) {
                    throw new ClientError('Не удалось получить список продуктов. Отпутствует скрытый блок продуктов');
                }

                if (dataName === 'Номер заказа') {
                    const thisId = infoItem.querySelector('a');

                    if (thisId === null || thisId === undefined) {
                        throw new ClientError('Не удалось получить id продуктов. Отпутствует скрытый блок продуктов');
                    }

                    if (thisId.getAttribute('href')) {
                        link = thisId.getAttribute('href') || 'null';
                    }

                    number = replace(thisId.innerHTML);
                } else if (dataName === 'Дата оформления') {
                    create = replace(infoItem.innerHTML);
                } else if (dataName === 'Статус') {
                    status = replace(infoItem.innerHTML);

                    if (status === 'Доставлен') {
                        color = 'green';
                    } else if (status === 'Возврат') {
                        color = 'red';
                    }

                    const currentStatusGroup = statusList.find(statusOj => statusOj.title === status);
                    const currentStatusGroupName = currentStatusGroup?.system_status;

                    if (statusGroupsActiveOrder.find(element => element === currentStatusGroupName)) {
                        isActive = true;
                    } else {
                        isActive = false;
                    }
                } else if (dataName === 'Оплата') {
                    paid = replace(infoItem.innerHTML);
                } else if (dataName === 'Сумма заказа') {
                    price = replace(infoItem.innerHTML);
                }
            });

            const format = (s: string) => s.substring(0, 6) + s.substr(8, 2);

            const obj = {
                number,
                create: format(create),
                status,
                link,
                paid,
                price,
                color,
                isActive
            };

            orderListData.push(obj);
        });

        return orderListData;
    }

    static renderList(data: TypeOrderData[], container: Element, text: string) {
        if (data.length >= 1) {
            data.forEach(item => {
                const orderListHtml = LiquidRender.render('order-item-template', { item });

                container.innerHTML += orderListHtml;
            });
        } else {
            container.innerHTML = LiquidRender.render('client-default-template', { text });
        }
    }

    static async renderBonuses(): Promise<ClientMaxmaI | undefined> {
        const containerBonuses = document.querySelector('[data-client-bonuses]') as HTMLElement;
        const containerPending = document.querySelector('[data-client-bonuses-pending]') as HTMLElement;
        const containerLevel = document.querySelector('[data-client-level]') as HTMLElement;
        const installCardNode = document.querySelector('.client-miles__install-card') as HTMLElement;
        const installCardLink = installCardNode.querySelector('.client-miles__install-card-link') as HTMLLinkElement;
        const { client } = await Client.get() as ClientResponseInt;

        if (! client?.phone) {
            // todo Сообщить клиенту что бонусы доступы только если указан телефон;
            return;
        }

        return Loyalty.getBalanceUser(client.phone)
            .then(res => {
                if (res && res.response && res.response.client) {
                    containerBonuses.innerHTML = res.response.client.bonuses.toLocaleString() || '0';
                    containerPending.innerHTML = res.response.client.pendingBonuses.toLocaleString() || '0';
                    containerLevel.innerHTML = res.response.level ? res.response.level.name : '';

                    if (! res.response.client.cardString && res.response.walletsLink && installCardLink) {
                        installCardLink.href = res.response.walletsLink;
                        installCardNode.classList.remove('client-miles__install-card_hidden');
                    }

                    return res;
                }

                return;
            })
            .catch(err => {
                throw new ClientError(err);
            });
    }

    static async renderOrderList() {
        const container = document.querySelector('.order-list');
        const data = await Client.getOrdersDataHTML();

        if (container === null) {
            throw new ClientError('container для order-list не определен');
        }

        const defaultOrderListHtml = LiquidRender.render('client-default-order-list-template', {});

        this.renderList(data, container, defaultOrderListHtml);
    }

    // Вспомогательный класс для renderActiveOrderList
    static activeOrderListCounter(data: TypeOrderData[], wrap: Element) {
        const counter = data.length;
        const counterContent = wrap.querySelector('.order-item__counnter');
        const counterTextWrap = wrap.querySelector('.order-item__counnter-text');
        const counterWrap = wrap.querySelector<HTMLElement>('.accordion__item-subline');

        if (counterContent === null) {
            throw new ClientError('Некуда складывать результаты подсчета активных заказов - order-item__counter');
        }
        if (counterWrap === null) {
            throw new ClientError('Обертка всех элементов текущего счетчика не определена');
        }
        if (counterTextWrap === null) {
            throw new ClientError('Некуда складывать результаты подсчета (текстовые) активных заказов');
        }

        let textCounter = 'заказ';

        if (counter === 1) {
            textCounter = 'заказ';
        } else if (counter >= 2 && counter <= 4) {
            textCounter = 'заказа';
        } else if (counter >= 5) {
            textCounter = 'заказов';
        }

        if (counter >= 1) {
            counterContent.innerHTML = String(counter);
            counterTextWrap.innerHTML = textCounter;
            counterWrap.style.display = 'flex';
        } else {
            counterWrap.style.display = 'none';
        }
    }

    static async renderActiveOrderList() {
        const wrap = document.querySelector('.activ-order-list');

        if (wrap === null || wrap === undefined) {
            throw new ClientError('wrap Списка активных продуктов не определен');
        }

        const container = wrap.querySelector('.order-active-list');
        const state = await Client.getOrdersDataHTML();

        const data = state.filter(item => item.isActive === true);

        if (container === null || container === undefined) {
            throw new ClientError('container для order-list не определен');
        }

        Client.activeOrderListCounter(data, wrap);

        this.renderList(data, container, 'Нет активных заказов');
    }

    static openContentClientPages() {
        const clientContent = document.querySelector('.client-account__content');
        const arr = [
            '/client_account/password/change',
            '/client_account/contacts/new',
            '/client_account/orders',
            '/client_account/login'
        ];

        if (clientContent) {
            if (arr.includes(window.location.pathname) || window.location.pathname.includes('/client_account/password/')) {
                clientContent?.classList.remove('client-account__active-base-content');
            } else {
                clientContent?.classList.add('client-account__active-base-content');
            }
        }
    }

    addLoader(wrapper: HTMLElement) {
        const loader = document.querySelector('.loader__wrap');

        if (! wrapper || ! loader) {
            return;
        }

        const cloneLoader = loader.cloneNode(true);

        wrapper.append(cloneLoader);
    }

    hiddenLoader(wrapper: HTMLElement) {
        const loader = wrapper.querySelector<HTMLDivElement>('.loader__wrap');

        if (loader) {
            loader.style.display = 'none';
        }
    }

    openLoader(wrapper: HTMLElement) {
        const loader = wrapper.querySelector<HTMLDivElement>('.loader__wrap');

        if (loader) {
            loader.style.display = 'flex';
        }
    }

    renderResult(wrapper: HTMLElement, message: string, isError: boolean) {
        const messageHTML = `
            <div class="result ${isError ? 'result_error' : ''}">${message}</div>
        `;

        wrapper.innerHTML += messageHTML;

        setTimeout(() => {
            wrapper.querySelector('result')?.remove();
        }, 3000);
    }

    handlerFormEditProfile() {
        const form = document.querySelector<HTMLFormElement>('form#contacts');

        if (! form) {
            return;
        }

        this.addLoader(form);

        form.addEventListener('submit', e => {
            e.preventDefault();

            this.openLoader(form);

            const url = form.getAttribute('action') + '.json';

            fetch(url, {
                method: ((form as HTMLFormElement).getAttribute('method') as string).toUpperCase(),
                body: new FormData(form)
            })
                .then(() => {
                    this.hiddenLoader(form);
                    this.renderResult(form, 'Данныу успешно сохранены', false);

                    setTimeout(() => {
                        window.location.href = '/client_account/login';
                    }, 2000);
                })
                .catch(() => {
                    this.hiddenLoader(form);
                    this.renderResult(form, 'Ошибка. Повторите попытку позже', false);
                });
        });
    }

    // createAllClietnData() {
    //      Client.get()
    //         .then(data => {
    //             const profile = {
    //                 email: data.client.email,
    //                 name: data.client.name,
    //                 dress: data.client.default_address.address_for_gis,
    //                 strit: data.client.default_address.address,
    //                 countryCode: data.client.default_address.country,
    //                 phone: data.client.phone,
    //                 orderCounter: data.client.orders_count,
    //                 id: data.client.id
    //             }
    //
    //             this.allClietnData.profil = profile;
    //
    //             console.log(this.allClietnData)
    //         })
    // }
}
