import Client from '@gate31/uikit/common/components/client/client';
import { Maxma, responseCalculate } from '@gate31/core/src/api/maxma';
import { VarProduct } from '@gate31/types';
import EventBus from 'EventBus';
import $ from 'jquery';
import { faro } from '@gate31/faro/src';
import Cart from 'Cart';
import { ClientResponseInt } from '@gate31/types/client';
import { Base } from '@gate31/core/src/libs/BaseComponent';

/*
Условия отображения
1. Если килент в группе СОТРУДНИКИ - скрываем программу лояльности
2. Есди клиент в группе HOME31 - акция только для товаров с меткой SALE. Если таких товаров нет - скрываем программу лояльности.
 */

interface bodyForCalculateAccrualInt {
    user: {
        phone: string;
        name: string;
        email: string;
        id: number;
    };
    client: ClientResponseInt['client'];
    fieldValues: { [key: string]: string };
    orderLine: Array<VarProduct>;
    loyalty: {
        usedBonuses: string | number;
    };
}
export interface ClientMaxmaI {
    response: {
        client: {
            phoneNumber: string;
            bonuses: number;
            pendingBonuses: number;
            fullName: string;
            /** Номер бонусной карты в виде строки */
            cardString?: string;
        };
        bonuses: Array<{
            expireAt: string;
            amount: number;
        }>;
        level?: {
            name: string;
        };
        walletsLink?: string;
    };
}
interface Bonuses {
    applied: number;
    collected: number;
    maxToApply: number | string;
}

const STATUS_DISCOUNT = {
    DISCOUNT_DELETED: 'DISCOUNT_DELETED'
};
const TECHNICAL_STRING = 'LOYALTY_DISCOUNT';
const TITLE_LIST = [
    {
        name: 'default',
        title: 'БОНУСЫ GATE31 MILES'
    },
    {
        name: 'Набор_футболок_BASE',
        title: 'Набор футболок'
    },
    {
        name: 'Скидка_партнерам:_30%',
        title: 'Программа лояльности'
    },
    {
        name: 'HOME31',
        title: 'СКИДКА HOME31'
    }
];

// На странице корзины запускается только если клиент авторизован
export class CartLoyaltyDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('CartLoyaltyDesk');

    state: {
        status: 'default' | 'discount' | 'remove-discount';
        bonuses: Bonuses;
        loyaltyValue: number | string;
    }
    wrapper: HTMLElement;
    inputField: HTMLInputElement;
    buttonSubmit: HTMLButtonElement;
    form: HTMLElement;
    variantButtons: NodeListOf<HTMLInputElement>;
    user: ClientResponseInt | undefined;
    collectedWrap: HTMLSpanElement;
    balanceWrap: HTMLSpanElement;
    orderList: VarProduct[] | [];
    hasCouponDiscount: boolean | undefined;
    buttonReset: HTMLElement;

    radioButtonUserBonuses: HTMLInputElement;
    hiddenForm: {
        form: HTMLFormElement;
        field: HTMLInputElement;
    }

    constructor(node: HTMLElement) {
        super(node);

        this.user = undefined;
        this.orderList = [];
        this.hasCouponDiscount = undefined;

        // nodes...
        this.wrapper = CartLoyaltyDesk.querySelector(CartLoyaltyDesk.cn.setElem('wrapper').toSelector());
        this.radioButtonUserBonuses = CartLoyaltyDesk.querySelector<HTMLInputElement>(CartLoyaltyDesk.cn.setElem('bonuses-use').toSelector());
        this.form = CartLoyaltyDesk.querySelector(CartLoyaltyDesk.cn.setElem('content').toSelector());
        this.inputField = CartLoyaltyDesk.querySelector<HTMLInputElement>(CartLoyaltyDesk.cn.setElem('input').toSelector());
        this.buttonSubmit = CartLoyaltyDesk.querySelector<HTMLButtonElement>(CartLoyaltyDesk.cn.setElem('loyalty-button').toSelector());
        this.buttonReset = CartLoyaltyDesk.querySelector<HTMLButtonElement>(CartLoyaltyDesk.cn.setElem('reset-button').toSelector());
        this.collectedWrap = CartLoyaltyDesk.querySelector(CartLoyaltyDesk.cn.setElem('header-button-counter-collected').toSelector());
        this.balanceWrap = CartLoyaltyDesk.querySelector(CartLoyaltyDesk.cn.setElem('header-button-counter-balance').toSelector());
        this.variantButtons = CartLoyaltyDesk.querySelectorAll<HTMLInputElement>('input' + CartLoyaltyDesk.cn.setElem('radio-btn').toSelector());
        this.hiddenForm = {
            form: CartLoyaltyDesk.querySelector<HTMLFormElement>(CartLoyaltyDesk.cn.setElem('hidden-form').toSelector()),
            field: CartLoyaltyDesk.querySelector<HTMLInputElement>(CartLoyaltyDesk.cn.setElem('hidden-form-value-field').toSelector())
        };

        this.state = {
            status: 'default',
            bonuses: {
                applied: 0,
                collected: 0,
                maxToApply: ''
            },
            loyaltyValue: this.inputField.value || STATUS_DISCOUNT.DISCOUNT_DELETED
        };

        this.callbackConnection();
    }

    callbackConnection() {
        if (this.hiddenForm && this.inputField) {
            this.synchronizedValue(this.inputField, this.hiddenForm.field);
            this.handleValue(this.inputField, this.hiddenForm.field);
        }

        this.handleForm();
        this.handleToggleVariant();
        this.handleButton();
        this.changeButton(this.inputField.value);
        this.resetForm();

        EventBus.subscribe('update_items:insales:cart', CartState => {
            CartLoyaltyDesk.setSaleCharacteristics(CartState.order_lines);

            this.defineStrategy(CartState.order_lines)
                .then(defineStrategy => {
                    if (defineStrategy !== 'disabled') {
                        // Доступно списание бонусов
                        this.process(CartState);
                    } else {
                        this.hiddenLoyalty();
                    }
                });
        });
    }

    resetForm() {
        this.buttonReset.addEventListener('click', () => {
            this.inputField.value = '';
            this.hiddenForm.field.value = '';

            this.hiddenForm.form.submit();
        });
    }

    handleButton() {
        this.inputField.addEventListener('input', e => {
            const target = e.target as HTMLInputElement;

            this.changeButton(target.value);
        });
    }

    changeButton(value: string) {
        if (value) {
            this.buttonSubmit.removeAttribute('disabled');
        } else {
            this.buttonSubmit.setAttribute('disabled', '');
        }
    }

    hiddenLoyalty() {
        this.wrapper.classList.add(CartLoyaltyDesk.cn.setElem('wrapper').setMode({ hidden: true }).toString());
    }

    openLoyalty() {
        this.wrapper.classList.remove(CartLoyaltyDesk.cn.setElem('wrapper').setMode({ hidden: true }).toString());
    }

    hiddenUseBonuses() {
        this.radioButtonUserBonuses.classList.add('hidden');
    }

    chooseShowOrHiddenUsedBonuses(opts: responseCalculate) {
        const isCoupon = Cart.order.coupon ? Cart.order.coupon.valid : false;
        const selectedDiscount = Cart.order.discounts[0] ? Cart.order.discounts[0].amount : 0;
        const maxToApplyBonuses = opts.response.calculationResult.bonuses.maxToApply;

        if (isCoupon && maxToApplyBonuses <= selectedDiscount) {
            this.hiddenUseBonuses();
        }
    }

    chooseShowOrHideLoyalty(opts: responseCalculate) {
        const bonuses = opts.response.calculationResult.bonuses;
        const rowOffers = opts.response.calculationResult.rows.find(row => row.offers && row.offers.length);
        const isDiscountHome31 = rowOffers ? rowOffers.offers.some(offer => offer.name === 'HOME31') : false;

        if (isDiscountHome31 && bonuses.collected === 0 && bonuses.maxToApply === 0) {
            return;
        }

        this.openLoyalty();
    }

    static getBalanceUser(userPhone: string): Promise<ClientMaxmaI> {
        return Maxma.getBalance(userPhone)
            .then(res => res.json());
    }

    async getUserData(): Promise<ClientResponseInt | undefined> {
        if (this.user) {
            return this.user;
        }

        this.user = await Client.get()
            .then(res => res.status === 'ok' ? res : undefined)
            .catch(() => undefined);

        return this.user;
    }

    updateHasCouponDiscount(discounts: Array<{
        discount: number | string;
        amount: number;
        description: string;
    }> | []) {
        if (! discounts.length) {
            this.hasCouponDiscount = false;
            return false;
        }

        if (discounts[0]?.description?.includes('Скидка по купону')) {
            this.hasCouponDiscount = true;
            return true;
        }

        this.hasCouponDiscount = false;
        return false;
    }

    getBodyForCalculateAccrual() {
        const loyaltyFieldSampleSale = CartLoyaltyDesk.querySelector<HTMLInputElement>(CartLoyaltyDesk.cn.setElem('hidden-form-sample-sale-field').toSelector());
        const loyaltyFieldSale = CartLoyaltyDesk.querySelector<HTMLInputElement>(CartLoyaltyDesk.cn.setElem('hidden-form-sale-field').toSelector());

        if (! this.user || ! this.user.client || ! this.user.client.phone) {
            return;
        }

        const body = {
            user: {
                phone: this.user.client.phone,
                name: this.user.client.name || '',
                email: this.user.client.email as string,
                id: this.user.client.id
            },
            fieldValues: {
                'loyalty-field-sample-sale': loyaltyFieldSampleSale?.value || '',
                'loyalty-field-sale': loyaltyFieldSale?.value || ''
            },
            client: this.user.client,
            orderLine: this.orderList,
            loyalty: {
                usedBonuses: this.state.loyaltyValue
            }
        };

        return body;
    }

    async calculateAccrual(body: bodyForCalculateAccrualInt) {
        const resultCalculate = await Maxma.calculatePurchase(body);

        if (resultCalculate.response) {
            this.chooseShowOrHideLoyalty(resultCalculate);
            this.chooseShowOrHiddenUsedBonuses(resultCalculate);
        }

        const resultCalculateBonuses = resultCalculate.response.calculationResult.bonuses;

        this.state.bonuses.maxToApply = resultCalculateBonuses.maxToApply;
        this.state.bonuses.collected = resultCalculateBonuses.collected;
        this.state.bonuses.applied = resultCalculateBonuses.applied;

        return this.state.bonuses;
    }

    resetLoyaltyDiscount() {
        this.hiddenForm.field.value = STATUS_DISCOUNT.DISCOUNT_DELETED;
        this.hiddenForm.form.submit();
    }

    handleToggleVariant() {
        const hiddenFormClass = CartLoyaltyDesk.cn.setElem('content_hidden').toString();

        this.variantButtons.forEach(input => {
            input.addEventListener('change', e => {
                const target = e.target as HTMLInputElement;

                // Использовать баллый
                if (target.value === 'Use-points') {
                    this.form.classList.remove(hiddenFormClass);
                }

                // Накапливать баллый
                if (target.value === 'Earn-points') {
                    this.form.classList.add(hiddenFormClass);

                    this.resetLoyaltyDiscount();
                }
            });
        });
    }

    handleValue(input: HTMLInputElement, output: HTMLInputElement) {
        input.addEventListener('input', () => {
            this.synchronizedValue(input, output);
        });
    }

    handleForm() {
        if (! this.buttonSubmit) {
            return;
        }

        this.buttonSubmit.addEventListener('click', () => {
            this.hiddenForm.form.submit();
        });
    }

    synchronizedValue(input: HTMLInputElement, output: HTMLInputElement) {
        output.value = input.value;

        if (input.value === '0' || input.value === '') {
            output.value = STATUS_DISCOUNT.DISCOUNT_DELETED;

            return STATUS_DISCOUNT.DISCOUNT_DELETED;
        }

        return input.value;
    }

    renderCollected() {
        this.collectedWrap.innerHTML = String(`+ ${this.state.bonuses.collected.toLocaleString()}`);
    }

    renderBalance() {
        this.balanceWrap.innerHTML = String(this.state.bonuses.maxToApply.toLocaleString());
    }

    addLimitation() {
        this.inputField.addEventListener('input', () => {
            const value = Number(this.inputField.value);
            const maxValue = Number(this.state.bonuses.maxToApply);

            if (typeof value === 'number' && typeof maxValue === 'number' && value > maxValue) {
                this.inputField.value = String(this.state.bonuses.maxToApply);
                this.hiddenForm.field.value = String(this.state.bonuses.maxToApply);
            }
        });
    }

    /**
     * Метод перезаписывает ТЕХНИЧЕСКОЕ НАЗВАНИЕ СКИДКИ на нормально (для стр оформления заказа) (для стра заказа)
     */
    static updateDiscountName() {
        const discountSaleHTML = document.querySelector('[data-discount-sale]');
        let discountSale = '';

        if (discountSaleHTML) {
            discountSale = discountSaleHTML.getAttribute('data-discount-sale') as string;
        }

        const nameHTMLListInOrder = document.querySelectorAll('.account-order-list__result-name');

        nameHTMLListInOrder.forEach(item => {
            if (item.innerHTML.indexOf(TECHNICAL_STRING) >= 0) {
                const objectTitle = TITLE_LIST.find(nameObj => nameObj.name === discountSale) || TITLE_LIST.find(nameObj => nameObj.name === 'default');

                if (objectTitle) {
                    item.innerHTML = objectTitle.title;
                }
            }
        });

        $(document).on('loaded:insales:payments', () => {
            const nameHTMLListInNewOrder = document.querySelectorAll('.discount-subtotal-description');

            nameHTMLListInNewOrder.forEach(item => {
                if (item.innerHTML.indexOf(TECHNICAL_STRING) >= 0) {
                    const objectTitle = TITLE_LIST.find(nameObj => nameObj.name === discountSale) || TITLE_LIST.find(nameObj => nameObj.name === 'default');

                    if (objectTitle) {
                        item.innerHTML = objectTitle.title;
                    }
                }
            });
        });
    }

    setOrderList(list: Array<VarProduct> | []): Array<VarProduct> | [] {
        return this.orderList = list;
    }

    async process(CartState: {
        // eslint-disable-next-line
        order_lines: Array<VarProduct>;
        discounts: Array<{
            discount: number | string;
            amount: number;
            description: string;
        }>;
    }) {
        this.setOrderList(CartState.order_lines);
        this.updateHasCouponDiscount(CartState.discounts);

        const user = await this.getUserData();

        if (! user) {
            return;
        }

        const bodyForCalculateAccrual = this.getBodyForCalculateAccrual();

        if (! bodyForCalculateAccrual) {
            return;
        }

        this.calculateAccrual(bodyForCalculateAccrual)
            .then(() => {
                this.renderCollected();
                this.renderBalance();
                this.addLimitation();
            })
            .catch(error => {
                return faro.api.pushError(error, {
                    context: {
                        section: 'maxma',
                        component: 'get-client'
                    },
                });
            });
    }

    /**
     * Записываем товары с метками в поля.
     * @param orderLines
     */
    static setSaleCharacteristics(orderLines: Array<VarProduct>) {
        const sampleSaleField = document.querySelector<HTMLInputElement>('#loyalty-field-sample-sale');
        const saleField = document.querySelector<HTMLInputElement>('#loyalty-field-sale');

        const ACTION_PROP_ID = 37421688;

        const sampleSaleListProductId: Array<number> = [];
        const saleListProductId: Array<number> = [];

        orderLines.forEach(orderLinesItem => {
            const isSampleSale = Boolean(orderLinesItem.product.characteristics.find(item => item.permalink === 'sample-sale' && item.property_id === ACTION_PROP_ID));
            const isSale = Boolean(orderLinesItem.product.characteristics.find(item => item.permalink === 'sale' && item.property_id === ACTION_PROP_ID));

            if (isSampleSale) {
                sampleSaleListProductId.push(orderLinesItem.id);
            }
            if (isSale) {
                saleListProductId.push(orderLinesItem.id);
            }
        });

        if (sampleSaleField) {
            sampleSaleField.value = sampleSaleListProductId.join(',');
        }
        if (saleField) {
            saleField.value = saleListProductId.join(',');
        }
    }

    /**
     * Определяем поведение программы лояльности на основе клиента и корзины
     * @param orderLines
     */
    async defineStrategy(orderLines: Array<VarProduct>): Promise<'disabled' | 'enabled' | 'partially'> {
        const ACTION_PROP_ID = 37421688;
        const CLIENT_GROUP_LIST = [
            {
                id: 24657,
                title: 'HOME31'
            },
            {
                id: 27303,
                title: 'Сотрудники'
            }
        ] as const;

        const clientGroupId = await Client.getGroupId();
        const clientGroup = clientGroupId ? CLIENT_GROUP_LIST.find(item => item.id === clientGroupId)?.title : undefined;

        const characteristicsList = orderLines
            .map(orderItem => orderItem.product.characteristics)
            .flat();

        const productSimpleSaleListLength = characteristicsList.filter(item => item.permalink === 'sample-sale' && item.property_id === ACTION_PROP_ID).length;
        const productSaleListLength = characteristicsList.filter(item => item.permalink === 'sale' && item.property_id === ACTION_PROP_ID).length;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const isSampleSale = Boolean(productSimpleSaleListLength);
        const isSale = Boolean(productSaleListLength);

        if (orderLines.length === productSimpleSaleListLength) {
            // У всех товаров sample-sale
            return 'disabled';
        }

        if (clientGroup === 'HOME31') {
            if (isSale) {
                return 'partially';
            }

            return 'disabled';
        }

        if (clientGroup === 'Сотрудники') {
            return 'disabled';
        }

        return 'enabled';
    }
}
