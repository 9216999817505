import { Faro, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';

//@ts-expect-error
export const faro = window.faro ? window.faro as Faro : initializeFaro({
    url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/397ecc7608fe980e3cb515b40ee0d0fd',
    paused: String(process.env.ENV) !== 'production',

    app: {
        name: 'gate31-online-store',
        version: '1.0.0',
        environment: 'production'
    },

    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can"t find variable: ZiteReader"',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // real error app
        'Can\'t find variable: _AutofillCallbackHandler',
        'InvalidStateError',
        'https://yandex.ru/\n',
        'window.webkit.messageHandlers',
        'https://mc.yandex.ru/',

        'The string did not match the expected pattern.',
        'Can\'t find variable: VK',
        'VK is not defined',
        'VK',
        'Ошибка при попытке получить список вариантов продукта для', // Предлагаю заигнорить ее пока сделать ничего с ней не можем
        'UnknownError: Database deleted by request of the user',
        'Non-Error promise rejection captured with value: undefined',
        'Non-Error promise rejection captured with value: null',
        'Can\'t find variable: PaymentAutofillConfig',
        'Attempt to get a record from database without an in-progress transaction',
        'Connection to Indexed Database server lost',
        'An internal error was encountered in the Indexed Database server',
        'roistatGoal is not defined',
        'Can\'t find variable: roistatGoal',
        'roistatGoal',
        'Произошла ошибка SSL. Безопасное подключение к серверу невозможно.',
        'pnn.cancel is not a function',
        'QuotaExceededError',
        'Unexpected end of input',
        'https://pagead2.googlesyndication.com/',
        'VideoPlayError',

        'Failed to fetch',
        'Load failed',
        'TypeError: Failed to fetch',

        'yandex.sovetnik', // Яндекс советник
        'yandexReadabilityChecker' // Яндекс Readability
    ],

    ignoreUrls: [
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /^file:\/\//i,
        /extensions\//i,
        /^chrome(-extension)?:\/\//i,
        // Safari extensions
        /safari-(web-)?extension:/i,
        // Blinger
        /widget\.blinger\.io\/widget\/blinger-widget\.js/i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // prod env
        /localhost/i,
        /api\.mindbox\.ru/i
    ],

    instrumentations: [
        ...getWebInstrumentations()
    ]
});
